<script>
import { humanize } from "underscore.string";

export default {
    name: "FormInput",
    props: {
        inputType: {
            type: String,
            default: "text",
        },
        attribute: {
            type: String,
        },
        label: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        value: {},
        modelValue: {},
        items: {
            type: Array,
            default: () => [],
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        minLength: {
            type: Number,
            default: 0,
        },
        maxLength: {
            type: Number,
            default: 1000,
        },
        trackBy: {
            type: String,
        },
        showClear: {
            type: Boolean,
            default: false,
        },
        openDirection: {
            type: String,
            default: "bottom",
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        modalValue: {},
        validation: {
            type: String,
        },
        labelKey: {
            type: String,
            default: "label",
        },
        valueKey: {
            type: String,
            default: "value",
        },
    },
    emits: ["input", "change", "update:modelValue"],
    data() {
        return {
            // If no label is set, just humanize the attribute name for convenience
            labelText: this.label || null, // humanize(this.attribute),
            placeholderText:
                this.placeholder || this.label || humanize(this.attribute),
            inputValueText: "",
            passwordType: "password",
            hasError: false,
            errorMessage: "",
        };
    },
    methods: {
        passwordToggle() {
            this.passwordType =
                this.passwordType === "password" ? "text" : "password";
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
    }
};
</script>

<template lang="pug">
.flex.flex-col.justify-start.items-start.max-w-md.mb-2(v-if="inputType === 'search'")
    input.input-field(
        v-if="inputType === 'search'",
        :name="attribute",
        v-model="inputValue",
        type="text",
        :disabled="disabled",
        :placeholder="placeholderText",
        @input="$emit('input', inputValue)",
        :show-clear="showClear"
    )
.flex.flex-col.justify-start.items-start.max-w-md.mb-5(v-else)
    label.label.mb-1 {{ label }}
    input.input-field(
        v-if="['text', 'email', 'number'].includes(inputType)",
        :name="attribute",
        :class="{ 'border-red-500': hasError }",
        v-model="inputValue",
        :type="inputType",
        :placeholder="placeholderText",
        :disabled="disabled",
        :required="required",
        :maxLength="maxLength",
        :minLength="minLength",
        @input="$emit('input', $event.target.value)"
    )
    input.input-field(
        v-if="inputType === 'textonly'",
        :name="attribute",
        v-model="inputValue",
        type="text",
        :disabled="disabled",
        :placeholder="placeholderText",
        :maxLength="maxLength",
        :minLength="minLength",
        onkeypress="return /^[a-zA-Z ]+$/i.test(event.key)",
        @input="$emit('input', $event.target.value)"
    )
    input.input-field(
        v-if="inputType === 'alphatext'",
        :name="attribute",
        v-model="inputValue",
        type="text",
        :disabled="disabled",
        :placeholder="placeholderText",
        :maxLength="maxLength",
        :minLength="minLength",
        onkeypress="return /^[a-zA-Z0-9]+$/i.test(event.key)",
        @input="$emit('input', $event.target.value)"
    )
    textarea.input-field(
        v-if="inputType === 'textarea'",
        :name="attribute",
        v-model="inputValue",
        type="textarea",
        :autosize="{ minRows: 2, maxRows: 4}"
        :disabled="disabled",
        :placeholder="placeholderText",
        @input="$emit('input', $event.target.value)"
    )
    input.input-field(
        v-if="inputType === 'textnumber'",
        :name="attribute",
        v-model="inputValue",
        type="tel",
        :disabled="disabled",
        :placeholder="placeholderText",
        :maxLength="maxLength",
        :minLength="minLength",
        onkeypress="return event.charCode >= 48 && event.charCode <= 57",
        @input="$emit('input', $event.target.value)"
    )

    input.input-field(
        v-if="inputType === 'password'",
        :name="attribute",
        :class="{ 'border-red-500': hasError }",
        v-model="inputValue",
        :type="passwordType",
        :disabled="disabled",
        :placeholder="placeholderText",
        @input="$emit('input', $event.target.value)"
    )
    span.fa.fa-eye.field-icon.toggle-password.cursor-pointer(
        v-if="inputType === 'password' && passwordType === 'password'",
        @click="passwordToggle"
    )
    span.fa.fa-eye-slash.field-icon.toggle-password.cursor-pointer(
        v-if="inputType === 'password' && passwordType === 'text'",
        @click="passwordToggle"
    )

    //- SELECT
    el-select.input-field(
        v-if="inputType === 'select'",
        v-model="inputValue",
        :disabled="disabled",
        :placeholder="placeholderText",
        :clearable="true",
        :filterable="true",
        @change="$emit('change', inputValue)"
    )
        el-option(
            v-for="item in items",
            :key="item[valueKey]",
            :label="item[labelKey]",
            :value="item[valueKey]"
        )
    .error-block(v-if="hasError")
        p {{ errorMessage }}
</template>