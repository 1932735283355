<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "HeaderComponent",
    data() {
        return {
            isOpen: true,
            dropdownOpen: false,
        };
    },
    methods: {
        handleCommand(command) {
            if (command === "logout") {
                this.logout;
            }
        },
    },
    computed: {
        ...mapActions("auth", ["logout"]),
        ...mapGetters("account", ["isDealer", "loggedInUser"]),
    },
};
</script>

<template lang="pug">
.header.flex.justify-between.items-center.p-2.bg-white.z-50.bg-gray-100.sticky.top-0
    .flex.items-center.w-full
        .relative
            a.inset-y-0.left-0.pl-3.py-1.flex.items-center(
                href="http://amitengineeringcompany.com/"
            )
                img.w-40(:src="require('@assets/logo.png')")
        .pr-3.ml-auto
            .flex.flex-row.justify-between.items-center
                .text-gray-500.mx-20(v-if="isDealer") 
                    span.text-2xl.text-green-500 Available Balance 
                    span.text-2xl.text-green-500 ₹  {{ loggedInUser.Dealer.availableBalance }}
                el-dropdown(trigger="click", @command="handleCommand")
                    span.el-dropdown-link
                        el-avatar(:size="40", icon="el-icon-user-solid") 
                    template(#dropdown="")
                        el-dropdown-menu
                            el-dropdown-item(command="logout") Logout
</template>

