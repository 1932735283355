<script>
import FormFieldInput from "@components/form-field-input";
import HeaderComponent from "@layouts/admin/components/header";
import state from "@utils/states";
import Modal from "@components/modal";
import TermsCondition from "@components/terms-condition";
import { mapGetters } from "vuex";

export default {
    name: "NocRegisteration",
    components: {
        FormFieldInput,
        HeaderComponent,
        Modal,
        TermsCondition,
    },
    data() {
        return {
            items: [
                { label: "New Registeration", value: "new" },
                { label: "Old Registeration", value: "old" },
            ],
            state,
            noc: {
                name: null,
                phone: null,
                email: null,
                type: null,
                state: null,
            },
            type: "",
            vehicalTypes: [
                { label: "E-rickshaw", value: "e_rickshaw" },
                // { label: "E-bike", value: "e_bike" },
                // { label: "E-bus", value: "e_bus" },
                // { label: "E-car", value: "e_car" },
            ],
            nocId: null,
            isAccepted: false,
            orderId: null,
            showTermsConditions: false,
        };
    },
    methods: {
        async createNocForm() {
            try {
                const { isValid, message } = this.$utility.nocValidation(
                    this.noc
                );
                if (
                    parseInt(this.loggedInUser?.Dealer?.availableBalance) <
                    parseInt(process.env.VUE_APP_NOC_AMOUNT)
                ) {
                    return this.$utility.showErrorMessage(
                        "Insufficient balance to make this payment."
                    );
                }
                if (!isValid) {
                    return this.$utility.showErrorMessage(message);
                }

                if (this.nocId) {
                    this.payNocAmount();
                    return;
                }

                const data = {
                    name: this.noc.name,
                    phone: this.noc.phone,
                    type: this.noc.type,
                    state: this.noc.state,
                    motorNumber: this.noc.motorNumber,
                    chassisNumber: this.noc.chassisNumber,
                    vehicleType: this.noc.vehicleType,
                };

                if (this.noc.email) {
                    data.email = this.noc.email;
                }

                data.createdBy = this.getDealer.id;

                const { object } = await this.$http.postWithoutHeaders(
                    "common/register-noc",
                    data
                );

                this.nocId = object.noc.id;
                // this.createOrder();
                this.payNocAmount();
                // this.$utility.goToPage("place.order", { nocId: noc.id });
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        async payNocAmount() {
            try {
                await this.$http.updateById(
                    "dealer/pay-noc-amount",
                    this.nocId
                );

                this.updateDealerBalance();
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        async createOrder() {
            try {
                const {
                    object: { order },
                } = await this.$http.postWithoutHeaders(
                    "common/craete-razorpay-order"
                );
                this.order = order;

                this.orderId = order.id;
                const razorpayConfig = {
                    key: process.env.VUE_APP_RAZORPAY_KEY,
                    order_id: this.order.id,
                    amount: this.order.amount,
                    currency: this.order.currency,
                    handler: this.handleRazorpayResponse,
                    name: "Amit Engineering",
                    prefill: {
                        name: this.noc.name,
                        contact: `+91${this.noc.phone}`,
                        email: this.noc.email,
                    },
                };

                const razor = new window.Razorpay(razorpayConfig);

                razor.open();
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        async handleRazorpayResponse(order) {
            try {
                const signature = await this.$http.rawPost(
                    "common/verify-razrpay-signature",
                    {
                        orderId: this.orderId,
                        signature: order.razorpay_signature,
                        paymentId: order.razorpay_payment_id,
                    }
                );

                if (signature.isSignatureValid) {
                    const result = await this.$http.rawPost(
                        "common/update-noc-order",
                        {
                            razorpayPaymentId: order.razorpay_payment_id,
                            razorpaySignature: order.razorpay_signature,
                            razorpayOrderId: order.razorpay_order_id,
                            nocId: this.nocId,
                        }
                    );

                    this.orderId = null;
                    this.$utility.showSuccessMessage(
                        "Noc sucessfully registered!"
                    );

                    this.updateDealerBalance();
                } else {
                    this.$utility.showErrorMessage(
                        "Your payment was not successfull! please contact your bank support!"
                    );
                }
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
        async updateDealerBalance() {
            try {
                const { dealer } = await this.$http.getById(
                    "dealer/details",
                    this.loggedInUser.Dealer.id
                );

                const result = {
                    address: dealer.address,
                    availableBalance: dealer.availableBalance,
                    district: dealer.district,
                    gstNumber: dealer.gstNumber,
                    id: dealer.id,
                    isApproved: dealer.isApproved,
                    panNumber: dealer.panNumber,
                    shopName: dealer.shopName,
                    state: dealer.state,
                    userId: dealer.userId,
                };
                this.$store.dispatch("account/updateDealer", result);

                this.$utility.goToPage("dealer.thank", {
                    nocId: this.nocId,
                });
            } catch (err) {
                this.$utility.showErrorMessage(err.message);
            }
        },
    },
    computed: {
        ...mapGetters("account", ["getDealer", "loggedInUser"]),
    },
};
</script>

<template lang="pug">
header-component
.container.px-4.py-8.text-center.mx-auto
    h2.text-center.text-4xl.mb-8.res-h1 Apply NOC Online
    .grid.grid-rows-2.grid-flow-col.gap-4.res-col
        .row-span-3.ml-auto.res-row
            form-field-input(
                inputType="textonly",
                placeholder="Enter name",
                attrinbute="name",
                v-model="noc.name",
                :required="true",
                label="Name"
            )
            form-field-input(
                inputType="textnumber",
                placeholder="Enter phone number",
                attrinbute="phone",
                v-model="noc.phone",
                :required="true",
                label="Phone",
                :minLength="10",
                :maxLength="10"
            )
            form-field-input(
                inputType="email",
                placeholder="Enter email",
                attrinbute="email",
                v-model="noc.email",
                :required="false",
                label="Email"
            )
            form-field-input(
                inputType="select",
                placeholder="Select State",
                attrinbute="state",
                v-model="noc.state",
                :required="true",
                label="State",
                labelKey="state",
                valueKey="state",
                :items="state",
                :clearable="true"
            )
        .row-span-3.mr-auto.res-row
            form-field-input(
                inputType="select",
                placeholder="Select registeration type",
                attrinbute="type",
                v-model="noc.type",
                :required="true",
                label="Registeration type",
                labelKey="label",
                valueKey="value",
                :items="items",
                :clearable="true"
            )
                //- :maxLength="17"
            form-field-input(
                inputType="alphatext",
                placeholder="Enter motor number",
                attrinbute="motorNumber",
                v-model="noc.motorNumber",
                :required="true",
                label="Motor Number",
                :maxLength="25"
            )
                //- :minLength="6",
                //- :maxLength="6"            
            form-field-input(
                inputType="alphatext",
                placeholder="Enter Chassis number",
                attrinbute="chassisNumber",
                v-model="noc.chassisNumber",
                :required="true",
                label="Chassis Number",
                :maxLength="25"
            )
            form-field-input(
                inputType="select",
                placeholder="Select Vehical Type",
                attrinbute="state",
                v-model="noc.vehicleType",
                :required="true",
                label="Vehical Type",
                labelKey="label",
                valueKey="value",
                :items="vehicalTypes",
                :clearable="true"
            )
                //- :minLength="17",
    .grid.grid-rows-1.grid-flow-col.gap-4.res-col
        .row-span-3.mx-auto.select-state.res-row
            el-checkbox.pt-8(v-model="isAccepted", size="large")
                p Accept
            span.pl-1.cursor-pointer.text-blue-500(
                @click="showTermsConditions = true"
            ) Terms And Conditions
            .text-center.mt-4
                el-button(
                    type="primary",
                    @click="createNocForm",
                    :disabled="!isAccepted"
                ) Pay and Apply
    modal(
        :visibility="showTermsConditions",
        @close="showTermsConditions = false",
        title="Terms & Conditions",
        width="55"
    )
        terms-condition
</template>